import CustomLayout from '../components/CustomLayout'
import Home from '../components/Home'

const HomePage = (props) => {
    return (
        <CustomLayout 
            showHomeSkeleton={true}
            showSiderExpanded={true}
            expandSiderInstantly={true}
        >
            <Home 
                {...props}
            />
        </CustomLayout>
    )
}

export default HomePage;